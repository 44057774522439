.hp-reinsurance__container {
  --v-space: var(--space-40);
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-neutral-300);
  padding-block: var(--v-space);
}
.hp-reinsurance__text {
  font-size: var(--fs-md);
  letter-spacing: 0.4px;
}
.hp-reinsurance__title {
  font-size: 1.5rem;
  margin-block-end: 2rem;
}
.hp-reinsurance__img {
  justify-content: center;
}
@media (min-width: 768px) {
  .hp-reinsurance__container {
    --v-space: var(--space-80);
    align-items: center;
  }
  .hp-reinsurance__content {
    width: 50%;
  }
  .hp-reinsurance__img {
    width: 50%;
  }
  .hp-reinsurance__title {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .hp-reinsurance__container {
    flex-direction: column-reverse;
  }
  .hp-reinsurance__img img {
    max-width: 170px;
  }
}